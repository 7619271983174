import React from "react";

const Logo = ({ width, color = "#fff" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width={width}
      // height={width / 4.28}
      viewBox="0 0 3168.6528957689175 740.2854557679198"
    >
      <g transform="scale(8.43264478844588) translate(10, 10)">
        <defs id="SvgjsDefs1469" />
        <g
          id="SvgjsG1470"
          featurekey="symbolFeature-0"
          transform="matrix(0.817767768626673,0,0,0.817767768626673,-1.4719820420193201,-6.812099036363583)"
          fill="#fff"
        >
          <title xmlns="http://www.w3.org/2000/svg">icons</title>
          <g xmlns="http://www.w3.org/2000/svg" data-name="15. circle saturn">
            <path d="M88.47,49.8C88.19,65.87,78.32,80.58,63,86A38.48,38.48,0,0,1,17,30,38.85,38.85,0,0,1,57.82,12.12C75.7,15.82,88.16,31.77,88.47,49.8c0,1.93,3,1.93,3,0A42,42,0,0,0,64.41,10.91a41.48,41.48,0,0,0-50,60.19A41.89,41.89,0,0,0,57.29,90.62c19.79-3.48,33.84-21,34.19-40.83C91.5,47.87,88.5,47.87,88.47,49.8Z" />
            <path d="M3.3,51.3H97.63a1.5,1.5,0,0,0,0-3H3.3a1.5,1.5,0,0,0,0,3h0Z" />
          </g>
        </g>
        <g
          id="SvgjsG1471"
          featurekey="nameLeftFeature-0"
          transform="matrix(1.1391094863838296,0,0,1.1391094863838296,97.26351687326556,4.27293418498224)"
          fill="#fff"
        >
          <path d="M6.6406 23.984 l6.0742 0.000019073 c1.0547 0 1.9596 -0.3776 2.7148 -1.1328 c0.74219 -0.74219 1.1133 -1.6406 1.1133 -2.6953 c0 -1.0677 -0.37109 -1.9791 -1.1133 -2.7344 c-0.75521 -0.75521 -1.6602 -1.1328 -2.7148 -1.1328 l-6.0742 0 l0 7.6953 z M6.6406 40.000019073 l-4.2383 -0.000019073 l0 -27.949 l10.313 0 c2.2266 0 4.1275 0.79428 5.7031 2.3828 c1.5885 1.5755 2.3828 3.483 2.3828 5.7227 c0 2.2266 -0.79428 4.1275 -2.3828 5.7031 c-1.5755 1.5755 -3.4766 2.3633 -5.7031 2.3633 l-6.0742 0 l0 11.777 z M24.2188 12.050999999999998 l0 27.949 l14.258 0 l0 -4.2383 l-10.02 0 l0 -7.6563 l7.7344 0 l0 -4.2383 l-7.7344 0 l0 -7.5781 l10.02 0 l0 -4.2383 l-14.258 0 z M57.24625 12.050999999999998 l-17.676 27.949 l5.0195 0 l4.1211 -6.5234 l9.4922 0 l0 6.5234 l4.2578 0 l0 -27.949 l-5.2148 0 z M58.20325 18.477 l0 10.762 l-6.8164 0 z M81.4258125 12.050999999999998 l-9.7656 12.129 l0 -12.129 l-4.2383 0 l0 27.949 l4.2383 0 l0 -9.043 l1.3086 -1.6211 l8.7695 10.664 l5.5078 0 l-11.563 -14.043 l11.172 -13.906 l-5.4297 0 z" />
        </g>
        <g
          id="SvgjsG1472"
          featurekey="nameRightFeature-0"
          transform="matrix(1.1390941725230328,0,0,1.1390941725230328,202.98381738258098,4.273230619173013)"
          fill="#fff"
        >
          <path d="M16.542975 12.09 l-4.2383 0 l0 27.91 l4.2383 0 l0 -27.91 z M37.266125 12.050999999999998 l0 19.98 l-11.934 -19.98 l-4.4336 0 l0 27.949 l4.2383 0 l0 -19.98 l11.953 19.98 l4.4336 0 l0 -27.949 l-4.2578 0 z M45.93755 12.050999999999998 l0 27.949 l14.258 0 l0 -4.2383 l-10.02 0 l0 -7.6563 l7.7344 0 l0 -4.2383 l-7.7344 0 l0 -7.5781 l10.02 0 l0 -4.2383 l-14.258 0 z M67.6172 23.984 l0 -7.6953 l6.0742 0 c1.0547 0 1.9596 0.3776 2.7148 1.1328 c0.74219 0.75521 1.1133 1.6667 1.1133 2.7344 c0 1.0547 -0.37109 1.9531 -1.1133 2.6953 c-0.75521 0.75521 -1.6602 1.1328 -2.7148 1.1328 l-6.0742 0 z M76.855 27.578 c1.4583 -0.625 2.6367 -1.6015 3.5352 -2.9297 c0.92447 -1.3542 1.3867 -2.8516 1.3867 -4.4922 c0 -2.2396 -0.79428 -4.1471 -2.3828 -5.7227 c-1.5755 -1.5885 -3.4766 -2.3828 -5.7031 -2.3828 l-10.313 0 l0 27.949 l4.2383 0 l0 -11.777 l4.7852 0 l6.1133 11.777 l4.7852 0 z M84.726575 12.050999999999998 l0 4.2383 l5.918 0 l0 23.711 l4.2578 0 l0 -23.711 l5.918 0 l0 -4.2383 l-16.094 0 z M108.90625625 12.09 l-4.2383 0 l0 27.91 l4.2383 0 l0 -27.91 z M128.90640625 12.050999999999998 l-17.676 27.949 l5.0195 0 l4.1211 -6.5234 l9.4922 0 l0 6.5234 l4.2578 0 l0 -27.949 l-5.2148 0 z M129.86340625 18.477 l0 10.762 l-6.8164 0 z" />
        </g>
      </g>
    </svg>
  );
};

export default Logo;
