import React from "react";
import { Link } from "gatsby";
import footerStyles from "./footer.module.scss";

const Footer = () => {
  const date = new Date();
  const style = {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: "0.8rem",
  };
  const style2 = {
    display: "flex",
    alignItems: "center",
    fontSize: "0.8rem",
  };
  const style3 = {
    width: "100%",

    paddingTop: "1rem",
    paddingBottom: "1rem",
  };

  return (
    <footer>
      <div className="footer">
        <div className="container" style={style}>
          <h4 className="subTitle is-size-5">Peak Inertia</h4>
          <div className={footerStyles.rightContainer}>
            <Link
              to="/"
              className="button is-text"
              style={{ fontSize: "0.8rem" }}
            >
              Home
            </Link>
            <Link
              to="/contact"
              className="button is-text"
              style={{ fontSize: "0.8rem" }}
            >
              Contact
            </Link>
            <p>© {date.getFullYear()} Peak Inertia, LLC</p>
          </div>
        </div>
      </div>
      <div
        style={style3}
        className="has-text-white has-background-grey-dark footer"
      >
        <div className="container" style={style}>
          <div style={style2}>
            <p style={{ paddingRight: "1rem" }}>
              Phone:{" "}
              <a className="has-text-white" href="tel:1-248-266-0452">
                (248)266-0452
              </a>
            </p>
            <p>
              Email:{" "}
              <a
                href="mailto:info@peakinertia.com?subject=Interested in working with Peak Inertia"
                className="has-text-white"
              >
                info@peakinertia.com
              </a>
            </p>
          </div>
          <a
            className="has-text-white"
            style={{ fontSize: "0.8rem" }}
            href="https://www.peakinertia.com"
          >
            www.peakinertia.com
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
