import React, { useState } from "react";
import "./style.scss";
import { Link } from "gatsby";
import Logo from "../components/svg/logo";
// import Logo from "../images/White-logo.svg";

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <nav className="navbar is-fixed-top is-primary">
      <div className="container">
        <div className="navbar-brand">
          <Link to="/" className="navbar-item">
            <Logo width="200px" />
            {/* <img
            src={Logo}
            alt=""
            style={{
              width: "224px",
            }}
          /> */}
          </Link>
          <span
            role="button"
            tabIndex={0}
            className={`navbar-burger ${menuOpen ? "is-active" : ""}`}
            aria-label="menu"
            aria-expanded="false"
            onKeyDown={() => setMenuOpen(!menuOpen)}
            onClick={() => setMenuOpen(!menuOpen)}
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </span>
        </div>
        <div className={`navbar-menu ${menuOpen ? "is-active" : ""}`}>
          <div className="navbar-end">
            <Link to="/services" className="navbar-item">
              Services
            </Link>
            <Link to="/team" className="navbar-item">
              Team
            </Link>
            {/* <Link to="/work" className="navbar-item">
              Work
            </Link> */}
            <span className="navbar-item">
              <Link className="button is-danger is-rounded" to="/contact">
                <span>Contact</span>
              </Link>
            </span>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
